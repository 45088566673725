import React from "react"
import Layout from "../components/layout"
import '../components/home.scss';
import '../components/christmas.scss';
import { Link } from "gatsby"

export default function ChristmasIntro() {
  return (
    <Layout>
      <div className="ChristmasIntroBackground">
        <div className="christmasButtonWrapper">
          <Link className="button christmas-button" to="/christmas/">Get Started!</Link>
        </div>
      </div>
    </Layout>
  )
}
